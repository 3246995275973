@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap');

$body-bg: #f4f4f4;
$body-color: #1a1a1a;

$font-family-base: 'Roboto', sans-serif;

$primary: #ff6500;
$navbar-dark-active-color: $primary;

$theme-colors: (
  'dark': #1a1a1a,
);

@import '~bootstrap/scss/bootstrap';

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title {
  font-family: 'Playfair Display', serif;
  font-weight: 900;
}

.navbar-brand {
  color: $primary !important;

  span {
    color: $body-bg;
  }
}

.btn-logout {
  color: rgba($color: $danger, $alpha: 0.8);
  &:hover,
  &:focus,
  &:active {
    color: rgba($color: $danger, $alpha: 1);
    text-decoration: none;
  }
}

.table td,
.table th {
  vertical-align: middle;
}

.table .thead-dark th {
  color: $body-bg;
  background-color: $body-color;
  border-color: #312d2d;
}

.btn-outline-dark {
  color: #232020;
  border-color: #232020;
  font-weight: 600;

  &:hover {
    color: #f4f4f4;
    background-color: #232020;
    border-color: #232020;
  }
}

.modal-header .close {
  outline: none;
}
.rbt-aux .rbt-close {
  margin-top: 0 !important;
}
@media only screen and (max-width: 575px) {
  .mt-xs-1 {
    margin-bottom: 1rem;
  }
}

@media (min-width: 720px) {
  .w-md-25 {
    min-width: 25%;
  }
}
